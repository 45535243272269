<template>
  <main class="core row-between">
      <sidebar v-if="!$route.meta.hideSidebar" @getHelpDetail="getHelpDetail"/>
      <div class="defaultModel bg-white" :class="{ model: !$route.meta.hideSidebar }">
        <div  v-html="helpDetail.content">
        </div>
        <!-- <router-view v-else></router-view> -->
      </div>
  </main>
</template>
<script>
import Sidebar from "./components/Sidebar";
export default {
  name: "",
  props: {},
  components: {
    sidebar: Sidebar,
  },
  data() {
    return {
      detailId:null,
      helpDetail:{}
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取详情信息
    getHelpDetail(id){
      this.detailId = id;
      this.$api.getHepDetailApi({id}).then(res=>{
        this.helpDetail = res.data;
      })
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
main {
  align-items: flex-start;
  margin: 30px auto;
}
.defaultModel {
  width: 100%;
  padding: 30px;
  &.model {
    width: 930px;
  }
}
</style>